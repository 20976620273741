export interface EnvironmentVariables {
  readonly appsyncEndpoint: string;
  readonly awsAccount: string;
  readonly awsRegion: string;
  readonly cloudFrontDomain: string;
  readonly cloudFrontProtocol: string;
  readonly identifyPoolDomain: string;
  readonly identityPoolId: string;
  readonly stage: string;
  readonly userPoolClientId: string;
  readonly userPoolId: string;
}

export const envVariables: EnvironmentVariables = {
  awsAccount: '934581382457',
  identityPoolId: 'us-east-1:e3439fdf-dfed-4822-bb4b-fba9327fa571',
  userPoolClientId: '5v33bb6dfmrubbmelphge33n4l',
  userPoolId: 'us-east-1_mccV9FlOc',
  identifyPoolDomain: 'acs-silencio-gamma.auth.us-east-1.amazoncognito.com',
  awsRegion: 'us-east-1',
  stage: 'gamma',
  cloudFrontProtocol: 'https',
  cloudFrontDomain: 'd3t2c6swbyrekx.cloudfront.net',
  appsyncEndpoint: 'api.gamma.device-rename.acs.amazon.dev',
};
