import { useQuery } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { getUserSites } from 'src/graphql/queries';
import { GetUserSitesQuery, Site } from 'src/API';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { log } from 'src/utils/helpers';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { selectUsername } from 'src/stores/slices/userSlice';
import { setAllowedSites } from 'src/stores/slices/isc/devicesSlice';
import { useEffect } from 'react';
import {
  Resource,
  ResourceAction,
  useHasResourcePermission,
} from '@hooks/useHasResourcePermission';

async function fetchUserSites(username: string): Promise<Site[]> {
  if (!username) {
    return [];
  }

  try {
    const response = (await API.graphql<GetUserSitesQuery>(
      graphqlOperation(getUserSites, { userId: username })
    )) as GraphQLResult<GetUserSitesQuery>;

    if (!response.data || !response.data.getUserSites) {
      return [];
    }

    return response.data.getUserSites.filter(Boolean) as Site[];
  } catch (error) {
    log('Error getting sites', true, { error });
    throw error;
  }
}

export function useUserSites() {
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);
  const { hasPermission: canListSites } = useHasResourcePermission(
    Resource.Site,
    ResourceAction.Read
  );

  const query = useQuery({
    queryKey: ['sites', username],
    queryFn: () => fetchUserSites(username || ''),
    enabled: !!username && canListSites,
    retry: (failureCount, error) => {
      // Don't retry if we get a 403 (forbidden)
      if (error instanceof Error && error.message.includes('403')) {
        return false;
      }

      return failureCount < 4;
    },
    retryDelay: attemptIndex => 1000 * attemptIndex,
  });

  useEffect(() => {
    if (query.data) {
      dispatch(setAllowedSites(query.data));
    }
  }, [dispatch, query.data]);

  return {
    data: canListSites ? query.data : undefined,
    isError: query.isError,
    isLoading: query.isLoading,
    isForbidden: !canListSites,
  };
}
